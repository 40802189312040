/*//https://drive.google.com/thumbnail?id=1mD1iDnEreESP5TJO6HJ5XcQJWiHVK8YM&sz=w800*/
.zoom-container {
  position: relative;
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100vh; /* Set the height to 300vh for the initial zoom effect */
  /*background-image: url('https://drive.google.com/thumbnail?id=1YHlQCSpwaz2HZfJ-fPJtqw3PPf2i519e&sz=w800'); !* Replace with your image URL *!*/
  /*background-size: cover;*/
  /*background-repeat: no-repeat;*/
  background-color: black;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}



